<template>
    <div class="page-wrapper">
        <div class="main-title-wrap" hero-transition-group>
            <img
                src="@/assets/img/pastille.svg"
                class="pastille"
                alt="Houblon Mellön"
                data-inview="fadeInUp"
                data-delay="100"
            />
            <h1 class="title special" data-inview="fadeInUp" data-delay="200">{{ $t('Bières Mellön') }}</h1>
            <svg
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-inview="fadeInUp"
                data-delay="200"
            >
                <path
                    d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                    fill="#BEA851"
                />
            </svg>
            <h2 class="title" data-inview="fadeInUp" data-delay="300"><span v-html="contentIsLoaded ? content.titre : '&nbsp;'"></span></h2>
        </div>

        <div class="beer-details" v-if="contentIsLoaded" hero-transition-group>
            <div class="img" data-inview="revealRight" data-delay="400">
                <div class="tag" v-if="content.nouveaute"><p class="text">{{ $t('Nouveauté') }}</p></div>
                <div class="types">
                    <div v-if="content.surPlace" class="type-tag">{{ $t('Sur place') }}</div>
                    <div v-if="content.aEmporter" class="type-tag">{{ $t('À emporter') }}</div>
                </div>
                <img :src="content.imageSimple.biere.url" class="main-img-beer" :alt="content.imageSimple.biere.titre" />
            </div>
            <div class="data" data-inview="slideLeft" data-delay="500">
                <div class="row full">
                    <h3 class="title">{{ content.sorte }}</h3>
                </div>
                <div class="row half separator">
                    <p class="number">{{ content.ibu }}</p>
                    <p class="text vert">IBU</p>
                </div>
                <div class="row half">
                    <p class="number">{{ content.alcVol }}</p>
                    <p class="text vert">ALC/VOL</p>
                </div>
                <div class="row half separator" v-for="(item, i) in content.formatsValeurs" :key="i">
                    <p class="text">{{ item.titre }} <span>{{ item.valeur }}</span></p>
                </div>
                <div class="content">
                    <div class="c-row" v-for="(item, i) in content.details" :key="i">
                        <div class="left">
                            <p class="text">{{ item.titre }}</p>
                        </div>
                        <div class="right">
                            <p class="text"><span v-html="item.texte"></span></p>
                        </div>
                    </div>
                </div>
                <a href="#contact" v-scroll-to:-0 class="row full link">
                    <h3 class="title">{{ $t('Visitez-nous') }}</h3>
                    <img src="@/assets/img/arrow-down.svg" alt="Arrow down" />
                </a>
            </div>
        </div>

        <div class="main-title-wrap" v-if="pageIsLoaded">
            <h1 class="title special" data-inview="fadeInUp" data-delay="100">{{ $t('Autres Bières') }}</h1>
            <svg
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-inview="fadeInUp"
                data-delay="100"
            >
                <path
                    d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                    fill="#BEA851"
                />
            </svg>
            <h2 class="title" data-inview="fadeInUp" data-delay="200">{{ $t('À boire également') }}</h2>
        </div>

        <div class="beers-list" v-if="pageIsLoaded">
            <div class="title-wrap" data-inview="fadeInUp" data-delay="100">
                <p class="title">{{ $t('Actuellement disponible') }}</p>
            </div>

            <router-link
                :to="item.page.jsonUrl"
                class="beer-wrap"
                data-inview="fadeInUp"
                :data-delay="1 * i + '00'"
                v-for="(item, i) in listeBieres" :key="item.page.id"
            >
                <div class="img">
                    <div class="tag" v-if="item.page.nouveaute"><p class="text">{{ $t('Nouveauté') }}</p></div>
                    <div class="types">
                        <div v-if="item.page.surPlace" class="type-tag">{{ $t('Sur place') }}</div>
                        <div v-if="item.page.aEmporter" class="type-tag">{{ $t('À emporter') }}</div>
                    </div>
                    <img :src="item.page.imageSimple.biere.url" class="main-img-beer" :alt="item.page.imageSimple.biere.titre" />
                </div>
                <div class="infos">
                    <div class="top">
                        <h4 class="title">{{ item.page.titre }}</h4>
                        <p class="text">{{ item.page.sorte }}</p>
                    </div>
                    <div class="bottom">
                        <div class="data">
                            <p class="number">{{ item.page.ibu }}</p>
                            <p class="text">IBU</p>
                        </div>
                        <div class="data">
                            <p class="number">{{ item.page.alcVol }}</p>
                            <p class="text">ALC<span>/VOL</span></p>
                        </div>
                        <div class="arrow">
                            <svg
                                width="25"
                                height="17"
                                viewBox="0 0 25 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M0 7.61401H23.6702V8.91004H0V7.61401Z" fill="#2E3E30" />
                                <path
                                    d="M16.7226 0L25 8.31128L16.7226 16.59L15.7915 15.6588L23.1393 8.31101L15.7915 0.930929L16.7226 0Z"
                                    fill="#2E3E30"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </router-link>

        </div>
        <div class="cta-wrap">
            <router-link :to="{ name: 'Bieres-' + $route.meta.lang }" class="cta" data-inview="scale" data-delay="100">
                <p class="text">{{ $t('Voir le menu complet') }}</p>
            </router-link>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'BieresEntry',

    components: {},

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        initInViewAnimations()
                    }, 1)
                }
            },
            immediate: true,
        },
    },

    computed: {
        listeBieres() {
            if (!this.pageIsLoaded) {
                return []
            }

            const results = this.globals.biereEntry.data.filter(i => i.page.id !== this.content.id && !i.page.anciennementEnFut)

            return results.slice(0, 6)
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
